<mat-toolbar class="homepage-toolbar">
  <h1 class="homepage-app-name"><span>INSTRUMENT</span> flow</h1>
  <!-- Added Additional Icons towards right side on the top Nav bar -->
  <span class="mainmenu-spacer"> </span>

  <!-- <button mat-icon-button [matMenuTriggerFor]="departmentmenu" >
    <mat-icon aria-hidden="false">  apps</mat-icon>
    <mat-menu #departmentmenu="matMenu" >
      <button mat-menu-item [routerLink]="['/']">
        <mat-icon>   bar_chart    </mat-icon>
        Dashboard
      </button> 
    </mat-menu>
  </button>  -->

  <button
    mat-icon-button
    [matMenuTriggerFor]="alertmenu"
    class="example-icon favorite-icon"
    aria-label="Example icon-button with heart icon"
  >
    <mat-icon>
      <span class="material-icons-outlined"> notifications </span>
    </mat-icon>
    <mat-menu #alertmenu="matMenu">
      <button mat-menu-item>
        <mat-icon> task_alt </mat-icon>
        Action
      </button>
      <button mat-menu-item>
        <mat-icon> insights </mat-icon>

        info
      </button>
      <button mat-menu-item>
        <mat-icon> settings_suggest </mat-icon>System
      </button>
    </mat-menu>
  </button>
  <mat-chip-list class="demo-button">
    <mat-chip
      color="warn"
      selected
      class="cpointer"
      (click)="compareProducts()"
      >Compare</mat-chip
    >
    <!-- <mat-chip color="accent" selected class="cpointer" (click)="resetCompare()" *ngIf="!isBuyer">Reset Compare</mat-chip> -->
    <!--<mat-chip color="primary" selected class="cpointer" (click)="login('seller')">Login as Seller</mat-chip>-->
    <mat-chip
      color="accent"
      selected
      class="cpointer"
      (click)="login('buyer')"
      *ngIf="!isBuyer"
      >Login</mat-chip
    >
    <mat-chip
      color="accent"
      selected
      class="cpointer"
      (click)="redirectToBuyerHomepage()"
      *ngIf="isBuyer"
      >Buyer's Homepage</mat-chip
    >
  </mat-chip-list>
  <button mat-icon-button [matMenuTriggerFor]="menu" class="mobile-menu">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="compareProducts()">
      <span>Compare</span>
    </button>
    <!-- <button mat-menu-item (click)="resetCompare()">
        <span>Reset Compare</span>
      </button> -->
    <button mat-menu-item (click)="login('seller')">
      <span>Login as Seller</span>
    </button>
    <button mat-menu-item (click)="login('buyer')" *ngIf="!isBuyer">
      <span>Login as Buyer</span>
    </button>
    <button mat-menu-item (click)="redirectToBuyerHomepage()" *ngIf="isBuyer">
      <span>Buyer's Homepage</span>
    </button>
  </mat-menu>

  <!-- <button mat-icon-button [matMenuTriggerFor]="profilemenu" class="example-icon" aria-label="Example icon-button with share icon">
    <mat-icon><span class="material-icons-outlined">
      account_circle
      </span></mat-icon>
      <mat-menu #profilemenu="matMenu">
        <button mat-menu-item (click)="openSettings()">
          <mat-icon>settings</mat-icon>Settings
        </button>
        <button mat-menu-item (click)="selectComponent('businessProfile')">
          <mat-icon>contact_page</mat-icon>Profile
        </button>
        <button mat-menu-item (click)="logout()">
          <mat-icon>power_settings_new</mat-icon>Logout
        </button>
      </mat-menu>
  </button> -->
</mat-toolbar>


<div class="basics" (scroll)="scrollDown($event)">
    <router-outlet></router-outlet>
    <div class="basic-footer">
        <mat-divider></mat-divider>
        <div class="fcol fsa basic-table">
            <div class="basic-row">
                <div class="frow basic-outer-cell">
                    <div class="basic-cell">
                        <h3>Get to Know Us</h3>
                        <ul>
                            <li><a href="/about">About Us</a></li>
                            <li><a href="/career">Careers</a></li>
                            <li><a href="/blog">Blog</a></li>
                        </ul>
                    </div>
                    <div class="basic-cell space-cell"></div>
                    <div class="basic-cell">
                        <h3>Social</h3>
                        <ul>
                            <li><a href="https://www.youtube.com/channel/UCpq4LxDQwQKvtCXbX03ZrCg/videos">YouTube</a></li>
                            <li><a href="https://twitter.com/instrumentflow">Twitter</a></li>
                            <li><a href="https://linkedin.com/company/instrumentflow">LinkedIn</a></li>
                        </ul>
                    </div>
                </div>
                <div class="basic-cell space-cell"></div>
                <div class="frow basic-outer-cell">
                    <div class="basic-cell">
                        <h3>Grow with Us</h3>
                        <ul>
                            <li><a href="">Programs</a></li>
                            <li><a href="">Affiliate</a></li>
                            <li><a href="">Advertise</a></li>
                        </ul>
                    </div>
                    <div class="basic-cell space-cell"></div>
                    <div class="basic-cell">
                        <h3>Let Us Help You</h3>
                        <ul>
                            <li><a href="https://my.instrumentflow.com">My Instrumentflow</a></li>
                            <li><a href="">Help</a></li>
                            <li><a href="/contact">Contact Us</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <mat-divider></mat-divider>
        <div>
            <div class="footer-product-links">
                <span class="link cpointer" *ngFor="let category of categories" (click)="addCategory(category)">{{category.category}}</span>
            </div>
            <div class="frow fcenter">
              <img src="../../../../assets/applogo/Brandlogo.svg" alt="">
          </div>
        </div>
        <div class="copyrights">
          <ul>
            <li class="link cpointer" (click)="redirectToPage('privacy')">Privacy</li>
            <li class="link cpointer" (click)="redirectToPage('terms')">Terms</li>
            <li class="link cpointer">Pricing</li>
            <li class="link cpointer">Refund Policy</li>
          </ul>
          <span>© 2022, Ensqual Technosolutions Private Limited.</span>
        </div>
        <div class="browser-footer">

        </div>
    </div>
</div>